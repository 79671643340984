import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2e7d2033 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _01cf7476 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _5ad6f4e9 = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _2b2d63f2 = () => interopDefault(import('../pages/account/funds.vue' /* webpackChunkName: "pages/account/funds" */))
const _4d391212 = () => interopDefault(import('../pages/account/loyalities.vue' /* webpackChunkName: "pages/account/loyalities" */))
const _ad126f76 = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _42241e05 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _d176bd94 = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _05fd11e1 = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _eb4c3f5e = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _349b5dad = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _872673f8 = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _247e9800 = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _0b352300 = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _205bb510 = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _89b57118 = () => interopDefault(import('../pages/product/lister/actie.vue' /* webpackChunkName: "pages/product/lister/actie" */))
const _173d5714 = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _208ac98d = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _8c2f2ea0 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _b3e0a99a = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _5d7ee9ba = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _7ad08066 = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _4ec61228 = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _183c27b2 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _04422609 = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _16932e34 = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _5e2f813a = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _031c8232 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _36ca5e60 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _07b257e6 = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _6499e04e = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _3d16e75c = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _0d5c4e4b = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _4b450d1f = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _0364d790 = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _36a22410 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _5440281f = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _a49ac0ae = () => interopDefault(import('../pages/review/detail/_slug.vue' /* webpackChunkName: "pages/review/detail/_slug" */))
const _961efcec = () => interopDefault(import('../pages/review/writing/_slug.vue' /* webpackChunkName: "pages/review/writing/_slug" */))
const _4cb46254 = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _618dd1a4 = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _704bfc4d = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _2f9a8521 = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _314a75e7 = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _07fb6a0d = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _3b3f2525 = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _717e87d8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2e7ab7e5 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _2e7d2033,
    children: [{
      path: "",
      component: _01cf7476,
      name: "account___nl"
    }, {
      path: "details",
      component: _5ad6f4e9,
      name: "account-details___nl"
    }, {
      path: "funds",
      component: _2b2d63f2,
      name: "account-funds___nl"
    }, {
      path: "loyalities",
      component: _4d391212,
      name: "account-loyalities___nl"
    }, {
      path: "newsletter",
      component: _ad126f76,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _42241e05,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _d176bd94,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _05fd11e1,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _eb4c3f5e,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _349b5dad,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _872673f8,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _247e9800,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _0b352300,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _205bb510,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/actie",
    component: _89b57118,
    name: "product-lister-actie___nl"
  }, {
    path: "/blog",
    component: _173d5714,
    name: "blog-category___nl"
  }, {
    path: "/cart",
    component: _208ac98d,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _8c2f2ea0,
    children: [{
      path: "",
      component: _b3e0a99a,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _5d7ee9ba,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _7ad08066,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _4ec61228,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _183c27b2,
      name: "checkout-payment___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _04422609,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _16932e34,
    name: "checkout-success___nl"
  }, {
    path: "/icon",
    component: _5e2f813a,
    name: "icon___nl"
  }, {
    path: "/login",
    component: _031c8232,
    name: "login___nl"
  }, {
    path: "/merk",
    component: _36ca5e60,
    name: "brand___nl"
  }, {
    path: "/vestigingen",
    component: _07b257e6,
    name: "store-lister___nl"
  }, {
    path: "/wachtwoord-vergeten",
    component: _6499e04e,
    name: "forgot-password___nl"
  }, {
    path: "/blocks/demo",
    component: _3d16e75c,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _0d5c4e4b,
    name: "forgot-password-reset___nl"
  }, {
    path: "/klantenservice/faq",
    component: _4b450d1f,
    name: "faq___nl"
  }, {
    path: "/login/guest",
    component: _0364d790,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _36a22410,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/blog/detail/:slug?",
    component: _5440281f,
    name: "blog-detail-slug___nl"
  }, {
    path: "/review/detail/:slug?",
    component: _a49ac0ae,
    name: "review-detail-slug___nl"
  }, {
    path: "/review/writing/:slug?",
    component: _961efcec,
    name: "review-writing-slug___nl"
  }, {
    path: "/p/:slug?",
    component: _4cb46254,
    name: "product-detail-slug___nl"
  }, {
    path: "/winkel/:slug?",
    component: _618dd1a4,
    name: "store-detail-slug___nl"
  }, {
    path: "/zoek/:slug?",
    component: _704bfc4d,
    name: "search-slug___nl"
  }, {
    path: "/blog/*",
    component: _2f9a8521,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _314a75e7,
    name: "blog-tag-all___nl"
  }, {
    path: "/merk/*",
    component: _07fb6a0d,
    name: "brand-lister-all___nl"
  }, {
    path: "/producten/*",
    component: _3b3f2525,
    name: "product-lister-all___nl"
  }, {
    path: "/",
    component: _717e87d8,
    name: "index___nl"
  }, {
    path: "/*",
    component: _2e7ab7e5,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
